import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import QuotesPage from "../quotes/QuotesPage";
import Base from "../Base.jsx";
import ViewQuote from "../quotes/view_quote/ViewQuote";
import HomePage from "../home/HomePage";
import CustomersPage from ".././customers/CustomersPage";
import BillingPage from "../billing/BillingPage";
import ViewCustomerBilling from "../customers/customer_billing/ViewCustomerBilling";
import OperationsPage from "../operations/OperationsPage";
import UnmappedWorkersPage from "../operations/unmapped_workers/UnmappedWorkersPage";
import CreateAccount from "../account_creation/CreateAccount";
import SetupAccount from "../account_creation/SetupAccount";
import ViewAssetPage from "../assets/ViewAssetPage";
import RenewalSurvey from "../surveys/RenewalSurvey";
import SurveyThankYou from "../surveys/SurveyThankYou";
import QuoteRequestPage from "../quote_requests/QuoteRequestPage";

export default function RouteComponent(props) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Base {...props} />}>
          <Route path="home_new" element={<HomePage {...props} />} />
          <Route path="quotes" element={<QuotesPage {...props} />} />
          <Route path="quotes/:quote_id" element={<ViewQuote {...props} />} />
          <Route path="quote_requests" element={<QuoteRequestPage {...props} />} />
          <Route path="customers" element={<CustomersPage {...props} />} />
          <Route
            path="customers_new/:customer_id/billing"
            element={<ViewCustomerBilling {...props} />}
          />
          <Route
            path={"assets_new/:asset_id"}
            element={<ViewAssetPage {...props} />}
          />
          <Route path="billing_new" element={<BillingPage {...props} />} />
          <Route
            path="operations_new"
            element={<OperationsPage {...props} />}
          />
          <Route
            path="operations_new/unmapped_workers"
            element={<UnmappedWorkersPage {...props} />}
          />
          <Route path="create-account" element={<CreateAccount {...props} />} />
          <Route path="accounts/setup" element={<SetupAccount {...props} />} />
          <Route
            path="surveys/renewal"
            element={<RenewalSurvey {...props} />}
          />
          <Route
            path="surveys/thanks"
            element={<SurveyThankYou {...props} />}
          />
        </Route>
      </Routes>
    </Router>
  );
}
