import React from "react";
import { PlusSmallIcon } from "@heroicons/react/20/solid";

const TitleCell = ({ children }) => {
  return <td className="p-3 border-r w-[190px] lg:w-[700px]">{children}</td>;
};

export default function QuoteRequestPage({ expired, quote_request }) {
  if (expired) {
    return (
      <div className={"mx-auto max-w-screen-xl"}>
        Link is expired. Please contact sales@cryptocaddy.us for a new quote
        request link.
      </div>
    );
  }
  console.log(quote_request);

  return (
    <div className="mx-auto max-w-screen-xl lg:p-8 font-medium text-sm lg:text-base">
      <img
        className="mx-auto h-20 w-auto text-center my-8"
        src="/images/cryptocaddy_inverted.png"
        alt="CryptoCaddy Logo"
      />
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-center lg:text-start text-xl lg:text-2xl font-bold">
          {quote_request.title}
        </h1>
      </div>

      {/* Important Items Section */}
      <div className="mb-0">
        <div className="bg-brand text-white p-3 font-bold">Important Items</div>
        <div className="border">
          <table className="w-full">
            <tbody>
              <tr className="border-b">
                <TitleCell>Quote Request Number</TitleCell>
                <td className="px-2">{quote_request.quote_request_number}</td>
              </tr>
              <tr className="border-b">
                <TitleCell>Request Date</TitleCell>
                <td className="px-2">{quote_request.request_date}</td>
              </tr>
              <tr>
                <TitleCell>
                  <strong>
                    Estimated Online Date<sup>1</sup>
                  </strong>
                </TitleCell>
                <td className="px-2">
                  <strong>
                    {quote_request.is_preorder
                      ? "Month following preorder batch"
                      : "5-7 business days after payment"}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Hosting Scope Section */}
      <div className="">
        <div className="bg-brand text-white p-3 font-bold">Hosting Scope</div>
        <div className="border">
          <table className="w-full">
            <tbody>
              {quote_request.items.map((item, ix) => {
                return (
                  <>
                    <tr className={`border-b ${ix !== 0 ? "border-t" : ""}`}>
                      <TitleCell>
                        <strong>Device</strong>
                      </TitleCell>
                      <td className="px-2">
                        <strong>{item.name}</strong>
                      </td>
                    </tr>
                    <tr className="border-b">
                      <TitleCell>Quantity</TitleCell>
                      <td className="px-2">{item.quantity}</td>
                    </tr>
                    <tr className="border-b">
                      <TitleCell>
                        Power consumption<sup>2</sup>
                      </TitleCell>
                      <td className="px-2">
                        {item.power.toLocaleString("en-US")} W
                      </td>
                    </tr>
                    <tr className={"border-b"}>
                      <TitleCell>Price per unit</TitleCell>
                      <td className="px-2">
                        ${item.price.toLocaleString("en-US")}
                      </td>
                    </tr>
                    <tr className={"border-b"}>
                      <TitleCell>Hosting Rate ($ / kWh)</TitleCell>
                      <td className="px-2">${item.rate} / kWh</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pricing and Terms Section */}
      <div className="">
        <div className="bg-brand text-white p-3 font-bold">
          Pricing and Terms
        </div>
        <div className="border">
          <table className="w-full">
            <tbody>
              <tr className="border-b">
                <TitleCell>Number of machines</TitleCell>
                <td className="p-3">{quote_request.items.length}</td>
              </tr>
              <tr className="border-b">
                <TitleCell>Contract Term</TitleCell>
                <td className="p-3">{quote_request.term} year(s)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Pricing Summary Section */}
      <div className="mb-4">
        <div className="bg-brand text-white p-3 font-bold">Pricing Summary</div>
        <div className="border">
          <table className="w-full">
            <tbody>
              <tr className="border-b">
                <TitleCell>Hardware Total</TitleCell>
                <td className="p-3">
                  ${quote_request.machines_price_total.toLocaleString("en-US")}
                </td>
              </tr>
              <tr className="border-b">
                <TitleCell>Installation Fee Total</TitleCell>
                <td className="p-3">
                  ${quote_request.install_fee_total.toLocaleString("en-US")}
                </td>
              </tr>
              <tr className="border-b">
                <TitleCell>Hosting Deposit</TitleCell>
                <td className="p-3">
                  ${quote_request.hosting_deposit_total.toLocaleString("en-US")}
                </td>
              </tr>
              <tr className="font-bold">
                <TitleCell>Total Due at Signing</TitleCell>
                <td className="p-3">
                  ${quote_request.total.toLocaleString("en-US")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Footnotes */}
      <div className="text-sm text-gray-600">
        <p>
          1- Online date subject to change depending on shipping time and
          facility availability.
        </p>
        <p>
          2- Power values are theoretical estimates and are subject to change.
        </p>
      </div>

      <div
        className={
          "flex flex-col lg:flex-row gap-4 my-8 lg:my-16 justify-center w-full"
        }
      >
        <a
          className="w-full max-w-screen-md flex items-center justify-center gap-x-1 rounded-md bg-brand p-3 text-sm lg:text-base font-semibold text-white shadow-sm hover:bg-brand-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"
          href={"https://cryptocaddy.us"}
          // onClick={() => (document.URL = "https://cryptocaddy.us")}
        >
          Move to Checkout
        </a>
        {/*<button*/}
        {/*  className="w-full flex items-center justify-center gap-x-1 rounded-md bg-brand p-3 text-sm lg:text-base font-semibold text-white shadow-sm hover:bg-brand-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand"*/}
        {/*  onClick={() => setModalOpen(true)}*/}
        {/*>*/}
        {/*  Request more Information*/}
        {/*</button>*/}
      </div>
    </div>
  );
}
